<template>
  <PanelBox title="Contentblokken">
    <template #toolbar>
      <button
        :disabled="disableOrderButton"
        class="btn btn-primary btn-xs"
        @click="disableDraggable = !disableDraggable"
      >
          <i class="fa fa-sort" /> Volgorde wijzigen
      </button>
    </template>
    <div class="row">
      <div class="col-md-6">
        <div class="tw-mb-4 tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-gap-4 tw-items-center">
          <h3 class="!tw-my-0">Standaard</h3>
          <div>
            <button
              type="button"
              title="Standaard contentblok toevoegen"
              :disabled="disableAddButton"
              class="btn btn-primary btn-xs"
              @click="addContentBlock(0)"
            >
              <i class="fa fa-plus" /> Nieuw
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <CountdownBar v-if="!disableDraggable" ref="countdownStandard" :timeLeft="3" :timeTotal="3" />
            <Draggable
              group="contentBlocks"
              v-model="standardContentBlocks"
              :options="{ disabled : disableDraggable }"
              @change="handleOrderChange(0)"
              @start="drag = true"
              @end="drag = false"
            >
              <ContentBlock
                v-for="contentBlock in standardContentBlocks"
                :key="contentBlock.id"
                :contentBlock="contentBlock"
                :pictures="pictures"
                :dragMode="!disableDraggable"
                :create-method="handleCreateContentBlock"
                :save-method="handleSaveContentBlock"
                :load-method="loadContentBlocks"
                @delete="handleDeleteContentBlock"
                @startEdit="contentBlockEditsInProgress += 1"
                @endEdit="contentBlockEditsInProgress -= 1"
              />
            </Draggable>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="tw-mb-4 tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-gap-4 tw-items-center">
          <h3 class="!tw-my-0">Highlight</h3>
          <div>
            <button
              type="button"
              title="Highlight contentblok toevoegen"
              :disabled="disableAddButton"
              class="btn btn-primary btn-xs"
              @click="addContentBlock(1)"
            >
              <i class="fa fa-plus" /> Nieuw
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <CountdownBar v-if="!disableDraggable" ref="countdownHighlight" :timeLeft="3" :timeTotal="3" />
            <Draggable
              group="contentBlocks"
              v-model="highlightContentBlocks"
              :options="{ disabled : disableDraggable }"
              @change="handleOrderChange(1)"
              @end="drag = false"
              @start="drag = true"
            >
              <ContentBlock
                v-for="contentBlock in highlightContentBlocks"
                :key="contentBlock.id"
                :contentBlock="contentBlock"
                :pictures="pictures"
                :dragMode="!disableDraggable"
                :create-method="handleCreateContentBlock"
                :save-method="handleSaveContentBlock"
                :load-method="loadContentBlocks"
                @delete="handleDeleteContentBlock"
                @startEdit="contentBlockEditsInProgress += 1"
                @endEdit="contentBlockEditsInProgress -= 1"
              />
            </Draggable>
          </div>
        </div>
      </div>
    </div>
  </PanelBox>
</template>

<script>
import throttle from 'lodash/throttle'

import Draggable from 'vuedraggable'
import PanelBox from '@/components/iam/PanelBox'
import CountdownBar from '@/components/iam/CountdownBar'
import ContentBlock from '@/components/properties/ContentBlock'

import { mapActions, mapGetters } from 'vuex'
import { questionModal, startLoadingModal, stopLoadingModal, successModal, errorModal } from '@/modalMessages'
import {
  getPropertyContentBlocks,
  createPropertyContentBlock,
  updatePropertyContentBlock,
  deletePropertyContentBlock,
  updatePropertyContentBlocksOrder
} from '@/services/properties'
import {
  getProjectContentBlocks,
  createProjectContentBlock,
  updateProjectContentBlock,
  deleteProjectContentBlock,
  updateProjectContentBlocksOrder
} from '@/services/projects'

export default {
  name: 'ContentBlocks',
  components: {
    Draggable,
    PanelBox,
    CountdownBar,
    ContentBlock
  },
  props: {
    propertyId: {
      required: false,
      type: [Number, String]
    },
    projectId: {
      required: false,
      type: [Number, String]
    }
  },
  data () {
    return {
      disableDraggable: true,
      highlightContentBlocks: [],
      standardContentBlocks: [],
      baseContentBlock: {
        type: 0,
        picture: null,
        title: {
          text_nl: '',
          text_fr: '',
          text_en: ''
        },
        description: {
          text_nl: '',
          text_fr: '',
          text_en: ''
        }
      },
      contentBlockEditsInProgress: 0
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyPictures', 'getProjectPictures']),

    pictures () {
      return this.propertyId
        ? this.getPropertyPictures(this.propertyId)
        : this.getProjectPictures(this.projectId)
    },
    disableOrderButton () {
      return this.contentBlockEditsInProgress !== 0
    },
    disableAddButton () {
      return this.contentBlockEditsInProgress !== 0 || !this.disableDraggable
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadPropertyPictures', 'loadProjectPictures']),

    async init () {
      try {
        const response = await Promise.all([this.loadPictures(), this.loadContentBlocks()])
        stopLoadingModal()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van gegevens, probeer het opnieuw.')
      }
    },
    async loadPictures () {
      const response = this.propertyId
        ? await this.loadPropertyPictures(this.propertyId)
        : await this.loadProjectPictures(this.projectId)
      return response
    },
    async loadContentBlocks () {
      const response = this.propertyId
        ? await getPropertyContentBlocks(this.propertyId)
        : await getProjectContentBlocks(this.projectId)

      const contentBlocks = response?.data?.results
      if (contentBlocks) this.splitContentBlocks(contentBlocks)
      return contentBlocks
    },
    splitContentBlocks (contentBlocks) {
      this.highlightContentBlocks = []
      this.standardContentBlocks = []
      contentBlocks.forEach(contentBlock => {
        if (contentBlock.type) {
          this.highlightContentBlocks.push(contentBlock)
          this.highlightContentBlocks.sort((a, b) => (a.order > b.order) ? 1 : -1)
        } else {
          this.standardContentBlocks.push(contentBlock)
          this.standardContentBlocks.sort((a, b) => (a.order > b.order) ? 1 : -1)
        }
      })
    },
    handleOrderChange (highlight) {
      let cBlocks = []

      if (highlight) {
        cBlocks = this.highlightContentBlocks
        this.$refs.countdownHighlight.progress()
      } else {
        cBlocks = this.standardContentBlocks
        this.$refs.countdownStandard.progress()
      }

      throttle(
        async () => {
          try {
            const payload = []
            let order = 0

            cBlocks.forEach(contentBlock => {
              payload.push({ id: contentBlock.id, order })
              order++
            })

            this.propertyId
              ? await updatePropertyContentBlocksOrder(this.propertyId, payload)
              : await updateProjectContentBlocksOrder(this.projectId, payload)

            const contentBlocks = await this.loadContentBlocks()
            return contentBlocks
          } catch (error) {
            errorModal('Fout bij verplaatsen van content block, probeer het opnieuw.')
          }
        },
        3500
      )()
    },
    addContentBlock (type) {
      const newContentBlock = {
        type,
        name: '',
        picture: null,
        title: {
          text_nl: '',
          text_fr: '',
          text_en: ''
        },
        description: {
          text_nl: '',
          text_fr: '',
          text_en: ''
        }
      }
      if (type) this.highlightContentBlocks.unshift(newContentBlock)
      else this.standardContentBlocks.unshift(newContentBlock)
      this.contentBlockEditsInProgress += 1
    },
    async handleCreateContentBlock (data) {
      try {
        startLoadingModal('Content block wordt aangemaakt')
        const response = this.propertyId
          ? await createPropertyContentBlock(this.propertyId, data)
          : await createProjectContentBlock(this.projectId, data)

        this.contentBlockEditsInProgress -= 1
        successModal('De content block aangemaakt.')
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het maken van content block, probeer het opnieuw.')
      }
    },
    async handleSaveContentBlock (id, data, translated) {
      try {
        startLoadingModal('Bezig met opslaan van content block...')
        const response = this.propertyId
          ? await updatePropertyContentBlock(id, data)
          : await updateProjectContentBlock(id, data)

        // When translation is done, we are not in edit mode
        if (!translated) this.contentBlockEditsInProgress -= 1
        successModal('De content block is opgeslagen')
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij opslaan van content block, probeer het opnieuw.')
      }
    },
    async handleDeleteContentBlock (contentBlockId) {
      try {
        const result = await questionModal('Weet je zeker dat je de content block wil verwijderen?')
        if (!result.value) return
        startLoadingModal('Bezig met verwijderen van content block...')

        this.propertyId
          ? await deletePropertyContentBlock(contentBlockId)
          : await deleteProjectContentBlock(contentBlockId)

        const contentBlocks = await this.loadContentBlocks()
        successModal('De content block is verwijderd')
        return contentBlocks
      } catch (error) {
        errorModal('Fout bij het verwijderen van content block, probeer het opnieuw.')
      }
    }
  }
}
</script>
