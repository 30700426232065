<template>
  <div class="row picture-picker-container">
    <div class="col-sm-4 picture-container" v-if="selected_picture">
      <img :src="selectedPictureUrl" class="img-responsive"/>
    </div>

    <div class="col-sm-8 button-container" :class="{'button-container-empty': !selected_picture}">
     <button @click="show" class="btn btn-primary btn-block" type="button">Kies foto</button>
    </div>

    <Modal :title="'Kies een foto'" ref="modal">
      <div class="row">
        <div :key="picture.id" class="col-sm-2" v-for="picture in pictures">
          <img :class="activeClass(picture.id)" :src="picture.url_thumbnail" @click="_selectPicture(picture.id)"
               class="img-responsive">
        </div>
      </div>
      <span slot="footer">
        <button @click="selectPicture" class="btn btn-primary" type="button">Kies foto</button>
        <a @click="hide" class="btn btn-white">Annuleren</a>
      </span>
    </Modal>
  </div>
</template>

<script>
import Modal from '../iam/Modal'

export default {
  name: 'PicturePicker',
  props: {
    value: Number,
    pictures: Array,
    returnValue: {
      type: String,
      default: 'id'
    }
  },
  components: {
    Modal
  },
  data () {
    return {
      selected_picture: this.value
    }
  },
  watch: {
    value (val) {
      this.selected_picture = val
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    selectPicture () {
      this.hide()
      if (this.returnValue === 'object') {
        this.$emit('input', this._getPictureObject(this.selected_picture))
      } else if (this.returnValue === 'picture_url') {
        this.$emit('input', this._getPictureObject(this.selected_picture).url)
      } else if (this.returnValue === 'thumbnail_url') {
        this.$emit('input', this._getPictureObject(this.selected_picture).url_thumbnail)
      } else {
        this.$emit('input', this.selected_picture)
      }
    },
    _selectPicture (pictureId) {
      this.selected_picture = pictureId
    },
    _getPictureObject (pictureId) {
      return this.pictures?.filter((picture) => picture.id === pictureId)[0]
    },
    activeClass (pictureId) {
      return pictureId === this.selected_picture ? 'active-picture' : ''
    }
  },
  computed: {
    selectedPictureUrl () {
      const picObject = this._getPictureObject(this.selected_picture)
      if (picObject) return picObject.url_thumbnail
      return ''
    }
  }
}
</script>

<style scoped>
  .active-picture {
    border: 5px solid #1ab394
  }
  .button-container-empty {
    margin-left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
  }

  .picture-picker-container {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }

  .picture-container {
    padding-left: 0;
  }

  .button-container {
    padding-right: 0;
  }

  .button-container button {
    margin-bottom: 0;
  }
</style>
