<template>
  <div>
    <div v-show="trigger" :class="[editorClasses, 'inlineContainer']">
      <input v-model="model" class="form-control" />
      <CharacterCounter
        v-if="charLimit"
        :value="model"
        :char-limit="charLimit"
      />
    </div>
    <div v-show="!trigger" :class="[displayClasses, 'inlineContainer']">
      <component :is="preformat ? 'pre' : 'span'" v-text="model" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InlineTextInput',
  components: {
    CharacterCounter: () => import(/* webpackChunkName: "CharacterCounter" */ '@/components/CharacterCounter')
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      required: true
    },
    trigger: {
      type: Boolean,
      default: false
    },
    preformat: {
      type: Boolean,
      default: false
    },
    displayClasses: {
      type: Array,
      default: () => {
        return []
      }
    },
    editorClasses: {
      type: Array,
      default: () => {
        return []
      }
    },
    charLimit: {
      type: Number
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}

</script>

<style scoped>
  .inlineContainer {
    margin-bottom: 5px;
  }
</style>
