<template>
  <div>
    <div class="form-group">
      <div class="tabs-container col-sm-12 no-padding" style="padding-bottom: 15px;">
        <ul class="nav nav-tabs">
          <li v-for="(tab, index) in tabs" :key="index" :class="{ 'active': index === 0 }">
            <a
              :href="`#tab-${generateTabName(tab.name)}-${uniqueID}`"
              aria-expanded="true"
              data-toggle="tab"
              class="tw-uppercase"
            >
              {{ tab.name }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :id="`tab-${generateTabName(tab.name)}-${uniqueID}`"
            :class="['tab-pane', { 'active': index === 0 }]"
          >
            <div class="panel-body">
              <button
                v-if="tab.translate && !trigger"
                class="btn btn-xs btn-primary"
                style="margin-left: 15px;"
                @click="doTranslation(
                  tab.translateSourceTabName,
                  tab.translateTargetTabName,
                  tab.translateSourceLanguage,
                  tab.translateTargetLanguage,
                  true
                )"
              >
                {{ tab.translateButtonMessage }}
              </button>
              <span v-for="(field, index) in tab.fields" :key="index" class="col-md-12 no-padding">
                <label class="col-md-2">{{ field.label }}</label>
                <div class="col-md-10">
                  <component
                    :is="getFieldComponent(field.type)"
                    v-model="values[field.name]"
                    :trigger="trigger"
                    :preformat="false"
                    :editor-classes="['col-md-10']"
                    :char-limit="field.charLimit"
                  />
                  <slot :name="`text-suffix-${field.name}`" />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

import InlineTextInput from '@/components/iam/inline_components/InlineTextInput'
import InlineTextBox from '@/components/iam/inline_components/InlineTextbox'
import InlineSmallTextBox from '@/components/iam/inline_components/InlineSmallTextbox'

import { errorModal, questionModal, startLoadingModal, stopLoadingModal } from '@/modalMessages'

export default {
  name: 'InlineTranslatable',
  components: {
    InlineTextInput,
    InlineTextBox,
    InlineSmallTextBox
  },
  props: {
    tabs: {
      required: true,
      type: Array
    },
    trigger: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      uniqueID: Math.random().toString(36).substr(2, 9),
      values: {}
    }
  },
  watch: {
    tabs: {
      immediate: true,
      handler (tabs) {
        tabs.forEach(tab => {
          tab.fields.forEach(field => {
            this.$set(this.values, field.name, field.value || '')
          })
        })
      }
    }
  },
  methods: {
    generateTabName (name) {
      return name.toLowerCase().replace(' ', '-')
    },
    getValues () {
      return this.values
    },
    async doTranslation (sourceTabName, targetTabName, sourceLanguage, targetLanguage, overwrite = false, showUI = true) {
      const promises = []
      if (showUI) startLoadingModal('Bezig met vertalen van teksten...')
      const sourceTab = this.tabs.find(x => x.name === sourceTabName)
      const targetTab = this.tabs.find(x => x.name === targetTabName)
      sourceTab.fields.forEach(field => {
        const fieldID = field.fieldID
        const sourceField = sourceTab.fields.find(x => x.fieldID === fieldID)
        const targetField = targetTab.fields.find(x => x.fieldID === fieldID)

        if (!overwrite && this.values[targetField.name].length !== 0) return

        const sourceValue = this.values[sourceField.name]
        const promise = new Promise((resolve, reject) => {
          if (sourceValue) {
            $.post({
              url: '/api/v3/property/descriptions/translate',
              data: {
                text: sourceValue,
                sourceLanguage,
                targetLanguage
              }
            }).done(data => {
              this.$set(this.values, targetField.name, data.text)
              resolve(data)
            }).fail(error => {
              reject(error)
              console.error(`Unable to translate '${sourceValue}' to target language: '${targetLanguage}'`)
              if (showUI) {
                errorModal(`Er ging iets fout bij het vertalen van de teksten van ${sourceLanguage} naar ${targetLanguage}!`)
              }
            })
          } else {
            console.info(`Didn't translate ${sourceField.name} because the source value is empty`)
            resolve()
          }
        })
        promises.push(promise)
      })
      const response = await Promise.all(promises)
      this.$emit('translated')
      stopLoadingModal()
      return response
    },
    getTabsToTranslate (tabs) {
      const tabsToTranslate = []
      tabs.forEach(tab => {
        const fields = tab.fields
        fields.forEach(field => {
          if (!this.values[field.name] && tabsToTranslate.indexOf(tab) === -1) {
            tabsToTranslate.push(tab)
          }
        })
      })
      return tabsToTranslate
    },
    async askForTranslation () {
      const translatableTabs = this.tabs.slice(1)
      const tabsToTranslate = this.getTabsToTranslate(translatableTabs)
      if (!tabsToTranslate.length) {
        return this.$emit('uncheckPersistToProperties')
      }
      const result = await questionModal('Wil je de niet vertaalde teksten automatisch vertalen vanuit het Nederlands?')
      if (!result.value) return
      tabsToTranslate.forEach(tab => {
        this.doTranslation(
          tab.translateSourceTabName,
          tab.translateTargetTabName,
          tab.translateSourceLanguage,
          tab.translateTargetLanguage,
          true
        )
      })
      this.$emit('uncheckPersistToProperties')
      return result.value
    },
    getFieldComponent (field_type) {
      switch (field_type) {
        case 'text_input':
          return 'InlineTextInput'
        case 'text_box':
          return 'InlineTextBox'
        case 'small_text_box':
          return 'InlineSmallTextBox'
        default:
          return 'InlineTextInput'
      }
    }
  }
}
</script>

<style scoped>
.content-box-edit-button {
  float: right !important;
  background: #18A689 !important;
  border-radius: 4px 4px 0 0;
}

.content-box-edit-button a {
  color: #ffffff !important;
  padding: 10px 10px 10px 15px !important;
}
</style>
