function getTranslatableConfig (translatables, charLimitConfig, medium) {
  const title = translatables.filter(translatable => translatable.field === 'title')[0]
  const socialTitle = medium === 'web' ? translatables.filter(translatable => translatable.field === 'social_title')[0] : {}
  const shortDescription = translatables.filter(translatable => translatable.field === 'short_description')[0]
  const description = translatables.filter(translatable => translatable.field === 'description')[0]
  const translatableConfig = [
    // Tht field "name" needs to be the same case as the translatableSourceTabName, translateTargetTabName,
    // translateSourceLanguage, and translateTargetLanguage as it is used for comparison in InlineTranslatable.vue
    {
      name: 'nl',
      fields: [
        {
          label: 'Titel',
          type: 'text_input',
          name: 'title_nl',
          value: title?.text_nl || '',
          fieldID: 'title',
          charLimit: charLimitConfig.title
        },
        {
          label: 'Korte beschrijving',
          type: 'text_box',
          name: 'short_description_nl',
          value: shortDescription?.text_nl || '',
          fieldID: 'short_description',
          charLimit: charLimitConfig.shortDescription
        },
        {
          label: 'Beschrijving',
          type: 'text_box',
          name: 'description_nl',
          value: description?.text_nl || '',
          fieldID: 'description',
          charLimit: charLimitConfig.longDescriptionNl
        }
      ]
    }, {
      name: 'fr',
      translate: true,
      translateSourceTabName: 'nl',
      translateTargetTabName: 'fr',
      translateSourceLanguage: 'nl',
      translateTargetLanguage: 'fr',
      translateButtonMessage: 'Vertalen uit NL',
      fields: [
        {
          label: 'Titel',
          type: 'text_input',
          name: 'title_fr',
          value: title?.text_fr || '',
          fieldID: 'title',
          charLimit: charLimitConfig.title
        },
        {
          label: 'Korte beschrijving',
          type: 'text_box',
          name: 'short_description_fr',
          value: shortDescription?.text_fr || '',
          fieldID: 'short_description',
          charLimit: charLimitConfig.shortDescription
        },
        {
          label: 'Beschrijving',
          type: 'text_box',
          name: 'description_fr',
          value: description?.text_fr || '',
          fieldID: 'description',
          charLimit: charLimitConfig.longDescriptionFr
        }
      ]
    }, {
      name: 'en',
      translate: true,
      translateSourceTabName: 'nl',
      translateTargetTabName: 'en',
      translateSourceLanguage: 'nl',
      translateTargetLanguage: 'en',
      translateButtonMessage: 'Vertalen uit NL',
      fields: [
        {
          label: 'Titel',
          type: 'text_input',
          name: 'title_en',
          value: title?.text_en || '',
          fieldID: 'title',
          charLimit: charLimitConfig.title
        },
        {
          label: 'Korte beschrijving',
          type: 'text_box',
          name: 'short_description_en',
          value: shortDescription?.text_en || '',
          fieldID: 'short_description',
          charLimit: charLimitConfig.shortDescription
        },
        {
          label: 'Beschrijving',
          type: 'text_box',
          name: 'description_en',
          value: description?.text_en || '',
          fieldID: 'description',
          charLimit: charLimitConfig.longDescriptionEn
        }
      ]
    }
  ]
  if (medium === 'web') {
    translatableConfig.forEach(config => {
      config.fields.splice(1, 0, {
        label: 'Social media titel',
        type: 'small_text_box',
        name: `social_title_${config.name}`,
        value: socialTitle[`text_${config.name}`],
        fieldID: 'social_title',
        charLimit: charLimitConfig.social
      })
    })
  }
  return translatableConfig
}

export default getTranslatableConfig
