<template>
  <div class="col-md-12">
    <div class="content-block">
      <div class="header row">
        {{ contentBlock.title.text_nl }}
        <span class="header__buttons">
          <i v-if="dragMode" class="drag-icon fa fa-arrows" />
          <template v-else>
            <div v-if="editMode" class="tw-flex tw-gap-1">
              <button type="button" class="btn btn-xs btn-primary" @click="handleSave(false)">
                <i class="fa fa-save" /> Opslaan
              </button>
              <button type="button" class="btn btn-xs btn-danger" @click="initialize(); editContentBlock(false)">
                <i class="fa fa-times" /> Annuleren
              </button>
            </div>
            <div v-else class="tw-flex tw-flex-row tw-gap-1">
              <button type="button" class="btn btn-xs btn-default" @click="editContentBlock(true)">
                <i class="fa fa-pencil" /> Bewerken
              </button>
              <button v-if="!isNew" class="btn btn-xs btn-danger" @click="handleDeleteContentBlock">
                <i class="fa fa-trash" /> Verwijderen
              </button>
            </div>
          </template>
        </span>
      </div>
      <div class="content row">
        <div v-if="!editMode" class="row">
          <img :src="(pictureObject && pictureObject.url_thumbnail) || ''" class="col-md-2 img-responsive" />
        </div>
        <div v-if="editMode && !isNew" class="form-group" style="overflow: hidden;">
          <label class="col-md-2 control-label no-padding">Type</label>
          <div class="col-md-10 no-padding">
            <select v-model="type" class="form-control">
              <option value="0">Standaard</option>
              <option value="1">Highlight</option>
            </select>
          </div>
        </div>
        <div v-if="editMode" class="form-group">
          <label class="col-md-2 control-label no-padding">Foto</label>
          <div class="col-md-10 no-padding">
            <PicturePicker v-model="picture" :pictures="pictures" />
          </div>
        </div>
        <InlineTranslatable
          ref="translatableComponent"
          :tabs="tabs"
          :trigger="editMode"
          @translated="handleSave(true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import InlineTranslatable from '@/components/iam/inline_components/InlineTranslatable'
import PicturePicker from '@/components/iam/PicturePicker'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ContentBlock',
  components: {
    InlineTranslatable,
    PicturePicker
  },
  props: {
    contentBlock: {
      required: true,
      type: Object
    },
    pictures: {
      required: true,
      type: Array
    },
    dragMode: {
      required: false,
      type: Boolean
    },
    createMethod: {
      required: true,
      type: Function
    },
    saveMethod: {
      required: true,
      type: Function
    },
    loadMethod: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      editMode: false,
      id: 0,
      name: '',
      type: 0,
      type_display: '',
      picture: null,
      isNew: false,
      tabs: [
        {
          name: 'nl',
          fields: [
            {
              label: 'Titel',
              type: 'text_input',
              name: 'title_nl',
              fieldID: 'title',
              value: ''
            },
            {
              label: 'Beschrijving',
              type: 'text_box',
              name: 'description_nl',
              fieldID: 'description',
              value: ''
            }
          ]
        },
        {
          name: 'fr',
          translate: true,
          translateSourceTabName: 'nl',
          translateTargetTabName: 'fr',
          translateSourceLanguage: 'nl',
          translateTargetLanguage: 'fr',
          translateButtonMessage: 'Vertalen uit NL',
          fields: [
            {
              label: 'Titel',
              type: 'text_input',
              name: 'title_fr',
              fieldID: 'title',
              value: ''
            },
            {
              label: 'Beschrijving',
              type: 'text_box',
              name: 'description_fr',
              fieldID: 'description',
              value: ''
            }
          ]
        },
        {
          name: 'en',
          translate: true,
          translateSourceTabName: 'nl',
          translateTargetTabName: 'en',
          translateSourceLanguage: 'nl',
          translateTargetLanguage: 'en',
          translateButtonMessage: 'Vertalen uit NL',
          fields: [
            {
              label: 'Titel',
              type: 'text_input',
              name: 'title_en',
              fieldID: 'title',
              value: ''
            },
            {
              label: 'Beschrijving',
              type: 'text_box',
              name: 'description_en',
              fieldID: 'description',
              value: ''
            }
          ]
        }
      ]
    }
  },
  computed: {
    pictureObject () {
      if (this.contentBlock.picture) {
        return this.pictures?.filter(picture => {
          return picture.id === this.contentBlock.picture
        })[0]
      } else {
        return null
      }
    }
  },
  watch: {
    contentBlock: {
      immediate: true,
      handler () {
        this.initialize()
      }
    }
  },
  methods: {
    initialize () {
      const copy = cloneDeep(this.contentBlock)

      this.name = copy.name
      this.type = copy.type
      this.type_display = copy.type_display
      this.picture = copy.picture

      this.tabs.forEach(tab => {
        tab.fields.forEach(field => {
          field.value = copy[field.fieldID][`text_${tab.name}`]
        })
      })

      if ('id' in copy) {
        this.id = copy.id
      } else {
        this.isNew = true
        this.editMode = true
      }
    },
    previewImage (picture) {
      this.$refs.popup.show(picture)
    },
    validateContentBlock (title, description, isRequired = false) {
      if (title || description) return title && description // if "title" or "description" exist, both should exist.
      else if (isRequired) return false // if no values exist, but are require, return false
      return true // if no values exist and are not required, return true
    },
    editContentBlock (edit) {
      this.editMode = edit
      this.$emit(edit ? 'startEdit' : 'endEdit')
    },
    async handleSave (translated = false) {
      const translatableValues = this.$refs.translatableComponent.getValues()

      const nlValuesValid = this.validateContentBlock(translatableValues.title_nl, translatableValues.description_nl, true)
      const frValuesValid = this.validateContentBlock(translatableValues.title_fr, translatableValues.description_fr)
      const enValuesValid = this.validateContentBlock(translatableValues.title_en, translatableValues.description_en)
      if (!nlValuesValid) return errorModal('NL waarden zijn verplicht')
      if (!(frValuesValid && enValuesValid)) return errorModal('Titel en beschrijving, beide zijn vereist.')

      const data = {
        name: this.name,
        type: this.type,
        picture: this.picture,
        title: {
          text_nl: translatableValues.title_nl,
          text_fr: translatableValues.title_fr,
          text_en: translatableValues.title_en
        },
        description: {
          text_nl: translatableValues.description_nl,
          text_fr: translatableValues.description_fr,
          text_en: translatableValues.description_en
        }
      }
      if (this.isNew) {
        const response = await this.createMethod(data)
        this.id = response.data.id
        this.isNew = false
      } else {
        await this.saveMethod(this.id, data, translated)
      }
      this.editMode = false
      // Load the content blocks, else save the translation first, then load it.
      const result = await this.$refs.translatableComponent?.askForTranslation()
      if (!result || translated) await this.loadMethod()
    },
    handleDeleteContentBlock () {
      this.$emit('delete', this.contentBlock.id)
    }
  }
}
</script>

<style scoped>
.content-block {
  clear: both;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0;
  display: block;
}

.header {
  background-color: #f5f5f5;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}

.header__buttons {
  float: right;
}

.content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid;
  border-width: 0 1px 1px 1px;
}
</style>
